import { contactUs } from './contactUs';
import { blockOnResize } from './blockOnResize';
import { smoothScrollPolyfill } from './smoothScrollPolyfill';
import { menu } from './menu';
import { treedomIframe } from './treedomIframe';

smoothScrollPolyfill;
contactUs();
blockOnResize();
menu();
treedomIframe();
