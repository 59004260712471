export const contactUs = () => {
  const mailButton = document.getElementById('open-mail-button');

  mailButton.addEventListener('click', (e) => {
    e.preventDefault();
    const mailSubject = document.getElementById('subject').value;
    const mailBody = document.getElementById('body').value;
    if (mailSubject !== '' || mailBody !== '')
      location.href = `mailto:info@fortitudegroup.it?subject=${mailSubject}&body=${mailBody}`;
  });
};
