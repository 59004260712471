export const menu = () => {
  document.addEventListener('click', (e) => {
    const menuToggleCheckbox = document.getElementById('checkbox');
    const isClickingOutsideMenu = !e.target.closest('.nav--toggle');
    const isMenuOpen = menuToggleCheckbox.checked === true;

    const isClosingMenu = isClickingOutsideMenu && isMenuOpen;
    if (isClosingMenu) {
      menuToggleCheckbox.checked = false;
    }
  });
};
